<template>
    <div>
        <a-row :gutter="24" type="flex">
            <a-col :span="24" class="mb-24">
                <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ padding: 0, }">
                    <template #title>
                        <a-row type="flex" align="middle">
                            <a-col :span="24" :md="12">
                                <h5 class="font-semibold m-0">首页</h5>
                            </a-col>
                        </a-row>
                    </template>
  
                    <!-- <button @click="test">123123</button> -->
                    <div class="display-content" v-html="content"></div>
  
                </a-card>
            </a-col>
        </a-row>
    </div>
  </template>
  
  <script>
  
  import { getHomeConfig,postTest } from "@/api/config.js";
  import axios from "axios";
  export default ({
    components: {
    },
    data() {
        return {
            content: '', // 绑定的文本内容
        }
    },
    mounted() {
        this.getHomeConfig();
    },
    methods: {
        test() {
            postTest();
          
        },
        getHomeConfig() {
            getHomeConfig({ type: 'home' }).then(res => {
                let data = res.data.data;
                if (data.length > 0) {
                    this.content = data[0].content;
                }
            })
        }
    }
  })
  
  </script>
  
  <style lang="scss" scoped>
  .ql-editor {
    height: 700px;
  }
  
  ::v-deep .ant-card-body {
    padding: 0 20px 20px 20px !important;
  }
  
  .save-btn {
    margin-top: 20px;
  }
  
  ::v-deep .display-content {
    p {
        margin-bottom: 0;
    }
  }
  </style>